// components/auth/VerificationPending.js
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';

const VerificationPending = () => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { resendVerificationEmail } = useContext(AuthContext);

  const handleResendVerification = async () => {
    try {
      await resendVerificationEmail();
      setMessage('Verification email sent successfully!');
      setError('');
    } catch (err) {
      setError('Failed to resend verification email. Please try again.');
      setMessage('');
    }
  };

  return (
    <div className="mx-auto max-w-lg my-8 p-4 text-center">
      <h2 className="text-2xl font-bold text-indigo-600 mb-4">
        Verify Your Email
      </h2>
      
      <p className="text-gray-600 mb-4">
        We've sent a verification email to your address. 
        Please check your inbox and click the verification link.
      </p>
      
      <div className="mt-4">
        <p className="text-sm text-gray-500 mb-2">
          Didn't receive the email?
        </p>
        <button
          onClick={handleResendVerification}
          className="text-indigo-600 hover:text-indigo-500"
        >
          Click here to resend
        </button>
      </div>

      {message && (
        <div className="mt-4 text-green-600">
          {message}
        </div>
      )}
      
      {error && (
        <div className="mt-4 text-red-600">
          {error}
        </div>
      )}
    </div>
  );
};

export default VerificationPending;