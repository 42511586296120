// components/auth/EmailVerification.js
import React, { useContext, useEffect, useState } from 'react';
import {  useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('verifying');
  const { verifyEmail } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const token = searchParams.get('token');
        if (!token) {
          setStatus('error');
          return;
        }

        await verifyEmail(token);
        setStatus('success');
        // Redirect to dashboard instead of login since user is now authenticated
        navigate('/loading')
      } catch (error) {
        setStatus('error');
      }
    };

    verifyToken();
  }, [searchParams, verifyEmail, navigate]);

  return (
    <div className="mx-auto max-w-lg my-8 p-4 text-center">
      {status === 'verifying' && (
        <div className="text-gray-600">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600 mx-auto mb-4"></div>
          <p>Verifying your email...</p>
        </div>
      )}
      {status === 'success' && (
        <div className="text-green-600">
          <p>Email verified successfully!</p>
          <p>Redirecting to login...</p>
        </div>
      )}
      {status === 'error' && (
        <div className="text-red-600">
          <p>Failed to verify email. The link may be expired or invalid.</p>
          <button 
            onClick={() => navigate('/login')}
            className="mt-4 text-indigo-600 hover:text-indigo-500"
          >
            Return to login
          </button>
        </div>
      )}
    </div>
  );
};

export default EmailVerification;