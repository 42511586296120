import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import CompanyForm from './companyForm';
import StudentForm from './studentForm';
import UserForm from './userForm';
import UniversityForm from './universityForm';


const RegisterForm = ({ setIsAuthenticated }) => {
    const [selectedOption, setSelectedOption] = useState('student');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [industry, setIndustry] = useState('');

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState(new Date())

    const [universityName, setUniversityName] = useState('')
    const [location, setLocation] = useState('')

  
    const [step, setStep] = useState(1);

    const [errorMessage, setErrorMessage] = useState(''); // Error messages
    const [isLoading, setIsLoading] = useState(false);


    const { register, googleAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const locationSearch = useLocation();

    // Helper to extract query parameters
    const getQueryParams = (search) => {
      const params = new URLSearchParams(search);
      return {
        error: params.get('error'),
        message: params.get('message'),
      };
    };
  
    // Handle query parameters on component load
    useEffect(() => {
      const { error, message } = getQueryParams(locationSearch.search);
  
      if (error) {
        setErrorMessage(message || 'An error occurred. Please try again.');
      }
    }, [locationSearch.search]);


    const resetCompanyForm = () => {
        setCompanyName('');
        setIndustry('');
    };

    const resetStudentForm = () => {
        setFirstName('');
        setLastName('');
        // setDateOfBirth('');
    };

    const resetUniversityForm = () => {
        setUniversityName('');
        setLocation('');
    };
    const handleNext = () => {
        setStep((prevStep) => prevStep + 1);

    };

    const handlePrevious = () => {
        setStep((prevStep) => {
            // Reset form data based on current step
            switch (prevStep) {
                case 2:
                    if (selectedOption === 'company') {
                        resetCompanyForm();
                    } else if (selectedOption === 'student') {
                        resetStudentForm();
                    } else if (selectedOption === 'university') {
                        resetUniversityForm();
                    }
                    break;
                // Add additional cases for other steps if needed
                default:
                    break;
            }

            return Math.max(1, prevStep - 1);
        });
    };


    const handleOptionChange = (value) => {
        setSelectedOption(value);
    };
    const handleIndustryChange = (industry) => {
        setIndustry(industry);
      };

  


    const handleFormSubmit = async (e) => {

        e.preventDefault();
        setIsLoading(true);

       
        try {

            if (selectedOption === "company" && companyName && industry) {
                const companyData = {
                    email: email,
                    password: password,
                    role: selectedOption, // Assuming selectedOption represents the user's role
                    companyName: companyName,
                    industry: industry,
                    location: location
                };
                await register(companyData, selectedOption);
                // setSuccessMessage('Registration successful! Please check your email to verify your account.');
      
                // Optionally redirect to a verification pending page
                setTimeout(() => {
                  navigate('/verification-pending');
                }, 3000);
                setErrorMessage('');
                // setIsAuthenticated(true);
                // console.log('Company created successfully:', createdCompany);

            }
            if (selectedOption === "student" && firstName && lastName && dateOfBirth) {
                const studentData = {
                    email: email,
                    password: password,
                    role: selectedOption, // Assuming selectedOption represents the user's role
                    firstName: firstName,
                    lastName: lastName,
                    dateOfBirth: dateOfBirth
                };
                await register(studentData, selectedOption);
                // setSuccessMessage('Registration successful! Please check your email to verify your account.');
      
                // Optionally redirect to a verification pending page
                setTimeout(() => {
                  navigate('/verification-pending');
                }, 3000);
                setErrorMessage('');
                // setIsAuthenticated(true);

                // console.log('student created successfully:', createdStudent);

            }
            if (selectedOption === "university" && universityName && location) {
                const universityData = {
                    email: email,
                    password: password,
                    role: selectedOption, // Assuming selectedOption represents the user's role
                    universityName: universityName,
                    location: location,
                };
                await register(universityData, selectedOption);
                //     
                // setSuccessMessage('Registration successful! Please check your email to verify your account.');
      
                // Optionally redirect to a verification pending page
                setTimeout(() => {
                  navigate('/verification-pending');
                }, 3000);
                setErrorMessage('');
                // setIsAuthenticated(true);

                // console.log('studen created successfully:', createduniversity);

            }

            // setError('');
            // setIsAuthenticated(true);

        }
        // }
        catch (error) {
          setErrorMessage(error.message);

        }
        finally {
          setIsLoading(false);
      }

    };
    


    return (

        <div className="mx-auto bg-white max-w-lg my-8 p-4 shadow-md rounded-lg bg-clip-border">
            <h1 className="text-center pt-8 text-2xl font-bold text-indigo-600 sm:text-3xl">Get started today</h1>

            {/* <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
                        hello mate
                    </p> */}
            <p className="text-center text-lg font-medium mt-2">New here ?</p>
            {errorMessage && (
        <div
          className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
{errorMessage.split(', ').map((msg, idx) => (
      <span key={idx} className="block text-left">{msg}</span>
    ))}        </div>
      )}
            {step === 1 && (
                <div class="mt-6">
                    <h1 class="text-gray-500 dark:text-gray-300">Select type of account</h1>

                    <div className="flex flex-col items-center justify-center space-y-4 mt-4">
  <button
    className={`flex items-center justify-center w-64 px-8 py-4 text-lg font-semibold ${selectedOption === 'company'
      ? 'text-white bg-indigo-600'
      : 'text-indigo-600 border border-indigo-600'
      } rounded-xl focus:outline-none shadow-md hover:scale-105 transition-transform`}
    onClick={() => handleOptionChange('company')}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-8 h-8"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="1.5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
      />
    </svg>
    <span className="ml-4">Company</span>
  </button>

  <button
    className={`flex items-center justify-center w-64 px-8 py-4 text-lg font-semibold ${selectedOption === 'student'
      ? 'text-white bg-indigo-600'
      : 'text-indigo-600 border border-indigo-600'
      } rounded-xl focus:outline-none shadow-md hover:scale-105 transition-transform`}
    onClick={() => handleOptionChange('student')}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-8 h-8"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="1.5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
      />
    </svg>
    <span className="ml-4">Student</span>
  </button>

  <button
    className={`flex items-center justify-center w-64 px-8 py-4 text-lg font-semibold ${selectedOption === 'university'
      ? 'text-white bg-indigo-600'
      : 'text-indigo-600 border border-indigo-600'
      } rounded-xl focus:outline-none shadow-md hover:scale-105 transition-transform`}
    onClick={() => handleOptionChange('university')}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-8 h-8"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="1.5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
      />
    </svg>
    <span className="ml-4">University</span>
  </button>
</div>

                </div>
            )}


            {/* {errorMessage && <p>{errorMessage}</p>} */}
            <form
                onSubmit={handleFormSubmit}
                className="mb-0 mt-6 space-y-4  p-4  sm:p-6 lg:p-2">

                {step === 2 && (<UserForm
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                />

                )}
                {step === 2 && selectedOption === 'company' && (
                    <CompanyForm
                        companyName={companyName}
                        setCompanyName={setCompanyName}
                        location={location}
                        setLocation={setLocation}
                        industry={industry}
                        handleIndustryChange={handleIndustryChange}
                    />

                )}
                {step === 2 && selectedOption === 'student' && (
                    <StudentForm
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        date={dateOfBirth}
                        setDate={setDateOfBirth}
                        // handleDateSelect={handleDateSelect}
                    />
                )}
                {step === 2 && selectedOption === 'university' && (
                    <div>
                    <UniversityForm
                        universityName={universityName}
                        setUniversityName={setUniversityName}
                        location={location}
                        setLocation={setLocation}
                    />
                    
                    </div>
                )}
                <div className="mt-4">
                

            </div>
                <div className="flex justify-content-between gap-2">
                    {step > 1 && (
                        <button 
                        type="button" // Add this

                        className="block border border-indigo-600 w-full rounded-lg bg-white px-5 py-3 text-sm font-medium text-indigo-600"

                        /* variant="secondary" */ onClick={handlePrevious}>
                            Previous
                        </button>
                    )}
                    {step < 2 ? (
                        <button /* variant="secondary" */ 
                        className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"

                        onClick={handleNext}>
                            Next
                        </button>
                    ) : (
                        // Update the submit button JSX
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`block w-full rounded-lg px-5 py-3 text-sm font-medium text-white
                                ${isLoading ? 'bg-indigo-400 cursor-not-allowed' : 'bg-indigo-600'}`}
                        >
                            {isLoading ? (
                                <div className="flex items-center justify-center">
                                    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none"/>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                                    </svg>
                                    Registering...
                                </div>
                            ) : 'Register'}
                        </button>
                    )}
                </div>
                {step < 2 &&  (

                  <button
                  type="button"
                  onClick={() => googleAuth(selectedOption)}
                  className="flex items-center gap-2 justify-center w-full bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" className='h-5 w-5' height="1em" viewBox="0 0 256 262"><path fill="#4285f4" d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622l38.755 30.023l2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"/><path fill="#34a853" d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055c-34.523 0-63.824-22.773-74.269-54.25l-1.531.13l-40.298 31.187l-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"/><path fill="#fbbc05" d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82c0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602z"/><path fill="#eb4335" d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0C79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"/>
                  </svg>
                  Continue with Google as a {selectedOption}
                  </button>
                  )}


            </form>
            <p className="text-center text-sm text-gray-500">
                you have an account?
                <Link to="/login" className="underline" >
                    Log In
                </Link>
            </p>
        </div>


    );
}

export default RegisterForm;
